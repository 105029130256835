import { Controller } from "@hotwired/stimulus";

export default class TopicShowController extends Controller {
  static targets = ["positionsTab", "statisticsTab", "discussionTab"];

  connect() {
    if (window.location.hash) {
      this.showTab(window.location.hash);
    } 
  }

  showTab(hash) {
    switch (hash.slice(1).toLowerCase()) {
      case "positions":
        this.positionsTabTarget.checked = true;
        break;
      case "statistics":
        this.statisticsTabTarget.checked = true;
        break;
      case "discussion":
        this.discussionTabTarget.checked = true;
        break;
    }
  }

  updateHash(event) {
    window.location.hash = event.target.value;
  }
}
