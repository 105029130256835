import { Controller } from "@hotwired/stimulus";
import axios from "axios";
export default class ChatNewController extends Controller {
  static targets = ["userList", "selectedUsers", "searchInput", "searchOutput"];

  connect() {
    console.debug('ChatNewController connected');
    this.selectedUserIds = new Set();
  }

  async search() {
    const response = await axios.post("/chats/search_users", { query: this.searchInputTarget.value });
    const users = response.data;
    this.users = users;
    this.renderUsers(users);
  }

  renderUsers(users) {
    this.searchOutputTarget.innerHTML = users.map(user => `
      <div class="flex items-center p-2 border rounded-lg shadow-sm hover:bg-gray-50 cursor-pointer gap-4" data-user-id="${user.id}" data-action="click->chat-new#selectUser">
        <avatar-component src="${user.avatar_url || ''}" username="${user.username}"></avatar-component>
        <span class="text-sm font-medium text-gray-700">${user.username}</span>
      </div>
    `).join('');
  }

  selectUser(event) {
    const userId = event.currentTarget.dataset.userId;
    if (!this.selectedUserIds.has(userId)) {
      this.selectedUserIds.add(userId);
      const user = this.users.find(u => u.id.toString() === userId);
      this.renderSelectedUser(user);
    }
  }

  renderSelectedUser(user) {
    const selectedUserElement = document.createElement('div');
    selectedUserElement.dataset.userId = user.id;
    selectedUserElement.classList.add('selected-user', 'flex', 'items-center', 'p-2', 'border', 'rounded-lg', 'shadow-sm', 'bg-blue-50', 'mb-2', 'gap-4');
    selectedUserElement.innerHTML = `
      <avatar-component src="${user.avatar_url || ''}" username="${user.username}"></avatar-component>
      <span class="text-sm font-medium text-gray-700">${user.username}</span>
      <button type="button" class="ml-auto text-gray-400 hover:text-gray-600" data-action="click->chat-new#removeUser">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
          <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd" />
        </svg>
      </button>
      <input type="hidden" name="chat[user_ids][]" value="${user.id}">
    `;
    this.selectedUsersTarget.appendChild(selectedUserElement);
  }

  removeUser(event) {
    const selectedUserElement = event.currentTarget.closest('.selected-user');
    const userId = selectedUserElement.dataset.userId;
    this.selectedUserIds.delete(userId);
    selectedUserElement.remove();
  }
}
